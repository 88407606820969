import React from "react";
import { Toolbar, Typography, Box, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
export default function Header(props) {
  return (
    <Box sx={{ marginTop: "0", paddinTop: "0" }}>
      <Toolbar>
        <Typography
          variant="h3"
          noWrap
          component="div"
          sx={{ flexGrow: 1, fontWeight: 600 }}
        >
          {props.sendHeader}
        </Typography>
        {/*
      <Button
          variant="outlined"
          style={{
            borderColor: "blue",
            color: "blue",
            borderRadius: "1.5rem",
            marginRight: "1rem",
          }}
        >
          <PersonIcon />
          My Prefrence
        </Button>
        <Button
          variant="outlined"
          style={{
            borderColor: "grey",
            color: "grey",
            borderRadius: "1.5rem",
          }}
        >
          <SettingsIcon />
          Manage
        </Button>*/}
      </Toolbar>
    </Box>
  );
}
