import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FC6945",
    },
    secondary: {
      main: "#FC6945",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
          maxWidth: "20rem",
          maxHeight: "3rem",
          padding: "1.5rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          marginLeft: "2rem",
          borderRadius: "1rem",
          color: "white",
        },
      },
    },
  },
});
export default theme;
