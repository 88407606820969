import React from "react";
import { Box, Typography, Button } from "@mui/material";

export default function BroadcastChannel(props) {
  const channel = props.sendChannel.Name;
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 2,
        textAlign: "left",
        border: "1px solid",
        fontSize: "0.875rem",
        backgroundColor: "white",
        marginTop: "0.5rem",
      }}
    >
      <Typography variant="h6" fontWeight="bold">Broadcast Channel</Typography>
      <Button
        variant="outlined"
        style={{ borderRadius: "20px", color: "black", borderColor: "black" }}
      >
        #{channel}
      </Button>
    </Box>
  );
}
