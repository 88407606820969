import React, { useState } from "react";
import { Button, TextField, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
export default function Header(props) {
  const [heading, setHeading] = useState(props.sendHeader);
  const [edit, setEdit] = useState(false);
  const handleChange = (e) => {
    setHeading(e.target.value);
  };
  function handleSubmit() {
    props.getData(heading);
    setEdit(false);
  }
  return (
    <Grid container spacing={6}>
      <Grid item xs={2}>
        {" "}
        <div style={{ marginTop: "15px" }}>
          <Button onClick={() => setEdit(true)}>
            <EditIcon color="primary" />
          </Button>
        </div>
      </Grid>
      <Grid item xs={8}>
        {" "}
        {edit ? (
          <TextField
            style={{ marinTop: "0rem" }}
            fullWidth
            varient="outlined"
            type="text"
            value={heading}
            onChange={handleChange}
          />
        ) : (
          <h1>{heading}</h1>
        )}
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{ padding: "1.75rem" }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
