import React from "react";
import { Avatar, AvatarGroup, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/material";
export default function Participants(props) {
  const participants = props.sendUsers;
  const colours = [];
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 2,
        textAlign: "left",
        border: "1px solid",
        fontSize: "0.875rem",
        backgroundColor: "white",
        marginTop: "0.5rem",
      }}
    >
      <Grid container direction="column">
        <Typography variant="h6" fontWeight="bold">Participants</Typography>
        <AvatarGroup total={participants.length}>
          {participants.map((participant) => (
            <Tooltip title={participant.Username}>
              <Avatar alt={participant.Username} src={participant.Image} />
            </Tooltip>
          ))}
        </AvatarGroup>
      </Grid>
    </Box>
  );
}
