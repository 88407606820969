import { Button, Card, IconButton, TextField, Grid } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const data = [
  "How do you feel today?",
  "What did you do since yesterday?",
  "What you will do today?",
  "Anything Blocking your progress?",
];

export default function QuestionsStandup(props) {
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState(props.sendQuestions);
  const handleChange = (e) => {
    setQuestion(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setQuestions([...questions, question]);
    props.getData([...questions, question]);
    setQuestion("");
  };
  function deleteQuestion(item) {
    //console.log(item);
    const finalQuestions = questions.filter((q) => q !== item);
    props.getData(finalQuestions);
    setQuestions(finalQuestions);
  }
  function changeBackground(e) {
    // // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    e.target.style.boxShadow = " 4px 4px 8px 4px rgba(0, 0, 0, 0.2)";
  }
  function changeBackground2(e) {
    e.target.style.boxShadow = "none";
  }
  return (
    <>
      <h3 style={{ color: "grey", marginBottom: "0rem" }}>Questions</h3>
      {questions.map((item) => (
        <Card
          style={{ padding: "0.5rem", marginBottom: "10px" }}
          onMouseOver={changeBackground}
          onMouseOut={changeBackground2}
          key={item}
        >
          {item}
          <div style={{ float: "right", color: "red" }}>
            <IconButton onClick={() => deleteQuestion(item)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </Card>
      ))}
      <form onSubmit={handleSubmit}>
        <Grid container direction="row">
          <Grid item xs={9}>
            <TextField
              fullWidth
              type="text"
              style={{ backgroundColor: "white" }}
              value={question}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem" }}
              onClick={handleSubmit}
            >
              Add Question
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
