import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import mainlogo from "../assets/apxor.jpg";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

export default function MiniDrawer() {
  const drawerWidth = "4rem";
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItem>
          <Link to="/dashboard">
            <img src={mainlogo} height="30rem" width="30rem" />
          </Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <Link to="/dashboard">
            <HomeIcon style={{ color: "black" }} />
          </Link>
        </ListItem>
      </List>
      <Divider />
      <List></List>
    </Drawer>
  );
}
