
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { set } from "date-fns/esm";
import logo from './assets/apxor.jpg';
import './login.css'

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [correct, setCorrect] = useState(true);
    const[count,setCount]=useState(0);
    const navigate = useNavigate();
    const navigateHome = () => {
        navigate('/dashboard');
    };
    const handleSubmit = () => {
        if (username === "Apxor" && password === "Apxor@123") { navigateHome() }
        else {
            setCorrect(false);
            console.log("Wrong Password")
        }


        setPassword("");
        setUsername("");
    }
    return (
    <div className="App">
        <img src={logo} className="logo" alt="Apxor" />
        {correct?<p></p>:<p>Wrong credentials</p>}
        <form className="form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">User Name</label>
            <input type="text" name="username" placeholder="Apxor" onChange={(e)=>setUsername(e.target.value)}/>
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" onChange={(e)=>setPassword(e.target.value)}/>
          </div>
          <button className="buttonlogin">Login</button>
        </form>
        </div>
    )
}

