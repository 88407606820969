import React, { useState } from "react";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import { Button } from "@mui/material";
import { withStyles } from "@mui/material";

const DAYS = [
  {
    key: "sunday",
    label: "S",
  },
  {
    key: "monday",
    label: "M",
  },
  {
    key: "tuesday",
    label: "T",
  },
  {
    key: "wednesday",
    label: "W",
  },
  {
    key: "thursday",
    label: "T",
  },
  {
    key: "friday",
    label: "F",
  },
  {
    key: "saturday",
    label: "S",
  },
];
export default function DaysOfTheWeek(props) {
  const [days, setDays] = useState(props.sendDays);
  function daysoftheweek(value) {
    setDays(value);
    props.getData(value);
  }
  return (
    <>
      <h3 style={{ color: "grey", marginBottom: "0" }}>Days of the Week</h3>
      <ToggleButtonGroup
        value={days}
        style={{ backgroundColor: "white" }}
        onChange={(event, value) => daysoftheweek(value)}
      >
        {DAYS.map((day, index) => (
          <ToggleButton key={day.key} value={index} aria-label={day.key}>
            {day.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}
