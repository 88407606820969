import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Emoji from "react-emoji-render";
import useStore from "../../../../Store/store";
import "./QuestionAndAnswer.css";

export default function QuestionAndAnswer({ questions }) {
  const params = useParams();
  //console.log(params.id);
  const burl = useStore((state) => state.burl);
  const baseurl = burl + `/standup/${params.id}/view`;
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const baseurl1 = burl + "/users";
  const [users, setUsers] = useState([]);
  useEffect(() => {
    axios.get(baseurl).then((response) => {
      setDetails(response.data);
      //console.log(response.data);
      setLoading(true);
    });
  }, []);
  useEffect(() => {
    axios.get(baseurl1).then((response) => {
      setUsers(response.data);
      console.log(response.data);
    });
  }, []);
  function getUserId(answer) {
    let a = "";
    let ans = "";
    //console.log(answer);
    for (let i = 0; i < answer.length; i++) {
      if (answer.charAt(i) === "<") {
        while (answer.charAt(i) !== ">") {
          a = a + answer.charAt(i);
          i++;
        }
       // console.log(a);
        ans += "@" + getUserName(a.substring(2));
        a = "";
      }
      if (answer.charAt(i) === ">") continue;
      ans += answer.charAt(i);
    }
    //console.log(ans);
    return ans;
  }

  function getUserName(id) {
    let a = "";
    users.map((user) => {
      if (user.Userid === id) {
        a = user.Username;
        return a;
      }
    });
    return a;
  }
  const colors = [
    "#8B86E2",
    "#7ED6E9",
    "#7EECB5",
    "#ECBF3C",
    "#E0BBE4",
    " #957DAD",
    "#D291BC",
    "#88D5D5",
  ];
  //const length = details[0].Questions.length;
  if (details === null)
    return (
      <div align="center">
        <h1>No Data</h1>
      </div>
    );
  else {
    return (
      <Box>
        {details
          .sort((a, b) => b.Timestamp - a.Timestamp)
          .map((detail) => (
            <>
              <Box style={{ paddingLeft: "2rem" }}>
                <Grid container>
                  <Avatar
                    sx={{ height: "3.5rem", width: "3.5rem" }}
                    src={detail.Image}
                  />
                  <div style={{marginLeft:"1rem"}}>
                  <Typography variant="h6" sx={{fontWeight:"bold"}}>{detail.Username}</Typography>
                  </div>
                 
                    
                </Grid>
              </Box>
              <Box>
                {
                  <Box>
                    {Array.from(Array(detail.Questions.length), (e, i) => {
                      return (
                        <Box
                          key={i}
                          style={{
                            borderLeft: "2px solid",
                            borderRadius: "5px!important",
                            borderColor:
                              colors[Math.floor(Math.random() * colors.length)],
                            borderLeftWidth: "5px",
                            marginLeft: "6rem",
                          }}
                        >
                          <Box
                            style={{
                              paddingLeft: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <Typography variant="h6" fontWeight="bold">
                              {detail.Questions[i]}
                            </Typography>
                            <Emoji
                              className="display-linebreak"
                              size={64}
                              text={getUserId(detail.Answers[i].Answer)}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                }
              </Box>
            </>
          ))}
      </Box>
    );
  }
}
