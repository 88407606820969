import Select from "react-select";
import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { orange } from "@mui/material/colors";
import axios from "axios";
import useStore from "../../../Store/store";
//import './participants.css'
export default function BroadCastChannel(props) {
  const burl = useStore((state) => state.burl);
  const baseurl = burl + "/channels";
  const [users, setUsers] = useState([]);
  useEffect(() => {
    axios.get(baseurl).then((response) => {
      setUsers(response.data);
     // console.log(response.data);
    });
  }, []);
  const [display, setDisplay] = useState(props.sendChannel);
  const transformed = users.map(({ Name, Id }) => ({
    label: Name,
    value: Id,
  }));
 // console.log(display);
  const transformed2 = { label: display.Name, value: display.Id };

  const colourStyles = {
    control: (styles) => {
      return {
        ...styles,
        padding: "0.5rem",
        "&:hover": {
          borderColor: "#FC6945",
        },
        borderColor: "#FC6945",
      };
    },
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: orange[100],
      };
    },
  };

  const ParticipantHandle = (e) => {
    setDisplay(e);
    const transformed = { Name: e.label, Id: e.value };
    props.getData(transformed);
  };
  return (
    <div>
      <h3 style={{ color: "grey", marginBottom: "0" }}>Channels</h3>
      <div>
        <Grid container spacing={5}>
          <Grid item xs={10} md={8}>
            <Select
              options={transformed}
              onChange={ParticipantHandle}
              styles={colourStyles}
              defaultValue={transformed2}
            ></Select>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
