import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, Typography, Grid } from "@mui/material";

export default function TimeSelector(props) {
  const [value, setValue] = useState(props.sendTime);
  const h = value.Hour < 10 ? ("0" + value.Hour).slice(-2) : value.Hour;
  const m = value.Minute < 10 ? ("0" + value.Minute).slice(-2) : value.Minute; // '04'

  function handleChange(e) {
   // console.log(e.target.value);
    setValue(e.target.value);
    props.getData(e.target.value);
  }
  return (
    <>
      <TextField
        id="time"
        style={{ backgroundColor: "white" }}
        type="time"
        defaultValue={h + ":" + m}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        onChange={handleChange}
        sx={{ width: "50%" }}
      />
    </>
  );
}
