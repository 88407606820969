import create from "zustand";

const useStore = create((set) => ({
  color: [
    "#8B86E2",
    "#7ED6E9",
    "#7EECB5",
    "#ECBF3C",
    "#E0BBE4",
    " #957DAD",
    "#D291BC",
    "#88D5D5",
  ],
  colors: ["B7C6E6"],
  burl: "https://slackbot.apxor.com/",
  index: 0,
  selectid: (id) =>
    set((state) => ({
      filteredData: state.data.filter((detail) => detail.id === id),
      index: id,
    })),
}));

export default useStore;
