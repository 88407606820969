import Schedule from "./components/schedule";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Questions from "./components/Questions";
import { Grid, Box, Typography } from "@mui/material";
import Participants from "./components/participants";
import BroadcastChannel from "./components/BroadcastChannel";
import Header from "./components/Header";
import QuestionAndAnswer from "./components/QuestionsAndAnswers";
import axios from "axios";
import useStore from "../../../Store/store";
import MiniDrawer from "../../Drawer";
export default function Upper() {
  const [loading, setLoading] = useState(false);
  const burl = useStore((state) => state.burl);
  const baseurl = burl + "/home";
  const [data, setData] = useState(null);
  const params = useParams();
  //console.log(params.id);

  useEffect(() => {
    axios.get(baseurl).then((response) => {
      setData(response.data);
      response.data.map((e) => {
        if (e.Id == params.id) {
          setData(e);
        }
      });

      setLoading(true);
    });
  }, []);
  //console.log(data);
  if (data === null) return <h1>Loading......</h1>;
  else {
    return (
      <>
        <MiniDrawer />
        <Header sendHeader={data.Name} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container direction="row" spacing={5}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Schedule sendTime={data.Start} sendDays={data.Days} />
            </Grid>
            <Grid item xs={8}>
              <Questions sendQuestions={data.Questions} />
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column">
                <Grid item>
                  <Participants sendUsers={data.Users} />
                </Grid>

                <Grid item>
                  <BroadcastChannel sendChannel={data.Channel} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <h3>Timeline</h3>
        <div style={{ paddingTop: "3rem" }}></div>
        <QuestionAndAnswer></QuestionAndAnswer>
      </>
    );
  }
}
