import React, { useState, useContext, useEffect } from "react";
//import TimeSelector from "./components/TimeSelector";
//import DaysOfTheWeek from "./components/DaysOfTheWeek";
//import QuestionsStandup from "./components/questionStandup";
//import BroadCastChannel from "./components/BroadCastChannel";
//import ParticipantChannel from "./components/Participants";
//import Header from "./components/Header";
import { ThemeProvider } from "@mui/material";
import theme from "../ComponentsEditAndNewStandup/Components/theme";
import MiniDrawer from "../Drawer";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useStore from "../../Store/store";
//import EndTimeSelector from "./components/EndTimeSelector";

import BroadCastChannel from "../ComponentsEditAndNewStandup/Components/BroadCastChannel";
import ParticipantChannel from "../ComponentsEditAndNewStandup/Components/Participants";
import Header from "../ComponentsEditAndNewStandup/Components/Header";
import TimeSelector from "../ComponentsEditAndNewStandup/Components/TimeSelector";
import DaysOfTheWeek from "../ComponentsEditAndNewStandup/Components/DaysOfTheWeek";
import QuestionsStandup from "../ComponentsEditAndNewStandup/Components/questionStandup";

export default function EditPage() {
  let navigate = useNavigate();
  const goHome = () => {
    navigate("/dashboard");
  //  console.log("Naavigated");
  };
  const params = useParams();
  //console.log(params.id);
  const [loading, setLoading] = useState(false);
  var timeArray = [];
  var timeArray2 = [];
  const burl = useStore((state) => state.burl);
  const baseurl = burl + "/home";
  const [data, setData] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  function getHeader(name) {
    setUpdatedData((prevData) => ({ ...prevData, Name: name }));
  }
  function getTime(time) {
    timeArray = time.split(":");
    setUpdatedData((prevData) => ({
      ...prevData,
      Start: { ...prevData.Start, Hour: parseInt(timeArray[0]) },
    }));
    setUpdatedData((prevData) => ({
      ...prevData,
      Start: { ...prevData.Start, Minute: parseInt(timeArray[1]) },
    }));
  }
  function getDays(res) {
    setUpdatedData((prevData) => ({ ...prevData, Days: res }));
  }
  function getQuestion(questions) {
    setUpdatedData((prevData) => ({ ...prevData, Questions: questions }));
  }
  function getChannel(channel) {
    setUpdatedData((prevData) => ({ ...prevData, Channel: channel }));
  }
  function getParticipants(people) {
    setUpdatedData((prevData) => ({ ...prevData, Users: people }));
  }
  function getEndTime(time) {
    timeArray2 = time.split(":");
    setUpdatedData((prevData) => ({
      ...prevData,
      End: { ...prevData.End, Hour: parseInt(timeArray2[0]) },
    }));
    setUpdatedData((prevData) => ({
      ...prevData,
      End: { ...prevData.End, Minute: parseInt(timeArray2[1]) },
    }));
  }

  useEffect(() => {
    axios.get(baseurl).then((response) => {
      setData(response.data);
      let filtered = response.data.map((e) => {
        if (e.Id == params.id) {
          setData(e);
          setUpdatedData(e);
        }
      });

      setLoading(true);
    });
  }, []);

 // console.log(data);
 // console.log(updatedData);

  if (data === null) return <h1>Loading.....</h1>;
  else {
    return (
      <>
        <ThemeProvider theme={theme}>
          <MiniDrawer />
          <div style={{ float: "right" }}>
            <Button
              variant="contained"
              onClick={() =>
                axios
                  .post(burl + `standup/${params.id}`, updatedData)
                  .then((response) => {
                    if (response.data.message === "Success") goHome();
                  })
              }
            >
              Update Data
            </Button>
          </div>
          <Header sendHeader={data.Name} getData={getHeader} />
          <h3 style={{ color: "grey", marginBottom: "0" }}>Start Time</h3>
          <TimeSelector sendTime={data.Start} getData={getTime} />
          <br />
          <h3 style={{ color: "grey", marginBottom: "0" }}>End Time</h3>
          <TimeSelector sendTime={data.End} getData={getEndTime} />
          <DaysOfTheWeek sendDays={data.Days} getData={getDays} />
          <QuestionsStandup
            sendQuestions={data.Questions}
            getData={getQuestion}
          />
          <BroadCastChannel sendChannel={data.Channel} getData={getChannel} />
          <ParticipantChannel
            sendParticipants={data.Users}
            getData={getParticipants}
          />
        </ThemeProvider>
      </>
    );
  }
}
