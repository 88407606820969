import React from "react";
//import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import DaysOfTheWeek from "./DaysOfTheWeek";
const matter =
  "Weekly from Monday to Friday, at 07:00 AM, in Asia/Kolkata timezone";
function Schedule(props) {
  const h =
    props.sendTime.Hour < 10
      ? ("0" + props.sendTime.Hour).slice(-2)
      : props.sendTime.Hour;
  const m =
    props.sendTime.Minute < 10
      ? ("0" + props.sendTime.Minute).slice(-2)
      : props.sendTime.Minute; // '04'
  let data = h + ":" + m + " " + "Asia/Kolkata TimeZone";
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 2,
        textAlign: "left",
        border: "1px solid",
        fontSize: "0.875rem",
        backgroundColor: "white",
      }}
    >
      <Typography variant="h6" style={{ fontWeight: "700" }}>
        Schedule
      </Typography>
      <Typography variant="body1">{data}</Typography>
      <br></br>
      <DaysOfTheWeek sendDays={props.sendDays}></DaysOfTheWeek>
    </Box>
  );
}

export default Schedule;
