import React from "react";
import "./newStandup/style.css";
import { Card, CardContent, Button, Grid, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useStore from "../Store/store";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./ComponentsEditAndNewStandup/Components/theme";
import "./dashboard.css";
import MiniDrawer from "./Drawer";
import axios from "axios";
import DaysOfTheWeek from "./view/Display/components/DaysOfTheWeek";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddOutlined from "@mui/icons-material/AddOutlined";

export default function Dashboard() {
  const colors = useStore((state) => state.colors);
  const details = useStore((state) => state.data);
  const selectid = useStore((state) => state.selectid);
  const burl = useStore((state) => state.burl);
  const baseurl = burl + "/home";
  const [inputText, setInputText] = useState("");
  const [id, setId] = useState(0);
  const [data, setData] = useState(null);
  const [deleteText, setDeleteText] = useState("");
  let filter = "";
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  let deleteHandler = (e) => {
    var input = e.target.value.toLowerCase();
    setDeleteText(input);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(baseurl).then((response) => {
      setData(response.data);
      //console.log(response.data);
      setLoading(true);
    });
  }, []);

  if (loading === false) {
    return (
      <h1>
        <iframe
          src="https://giphy.com/embed/QBd2kLB5qDmysEXre9"
          width="480"
          height="288"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </h1>
    );
  } else {
    filter =
      data !== null &&
      data.filter((d) => {
        if (inputText === "") {
          return d;
        } else if (!d.Name.toLowerCase().includes(inputText)) {
          return "";
        } else {
          return d.Name.toLowerCase().includes(inputText);
        }
      });
    //console.log(filter);
    return (
      <ThemeProvider theme={theme}>
        <MiniDrawer />
        <div style={{ padding: 30 }}>
          <div style={{ float: "right", marginBottom: 10 }}>
            <Link to="/newStandup" style={{ textDecoration: "none" }}>
              <Button variant="contained">
                New StandUp
              </Button>
            </Link>
          </div>
          <TextField
            id="outlined-basic"
            onChange={inputHandler}
            variant="outlined"
            fullWidth
            label="Search"
          />
          
          {data !== null && filter.length !== 0 ? (
            filter.map((item) => (
              <Grid
                container
                spacing={10}
                justify="center"
                key={item.id}
                columnSpacing={100}
              >
                <Grid item xs={12}>
                  <Card
                    style={{
                      minWidth: "50rem",
                      marginBottom: 2,
                      marginTop: 1,
                      backgroundColor:
                        colors[Math.floor(Math.random() * colors.length)],
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        style={{ fontWeight: "bold" }}
                      >
                        {item.Name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 20 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {item.Start.Hour < 10
                          ? "0" + item.Start.Hour
                          : item.Start.Hour}
                        {":"}
                        {item.Start.Minute < 10
                          ? "0" + item.Start.Minute
                          : item.Start.Minute}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <DaysOfTheWeek sendDays={item.Days}></DaysOfTheWeek>
                      </Typography>
                      <div style={{ display: "flex", padding: "10px" }}>
                        <AvatarGroup
                          total={item.Users.length}
                          max={10}
                          sx={{
                            "& .MuiAvatar-root": { width: 50, height: 50 },
                          }}
                        >
                          {item.Users.map((participant) => (
                            <Tooltip title={participant.Username}>
                              <Avatar
                                alt={participant.Username}
                                src={participant.Image}
                              />
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      </div>
                      <Button
                        style={{
                          color: "black",
                          borderColor: "black",
                          borderRadius: "35px",
                          marginLeft: "0px",
                        }}
                        variant="outlined"
                      >
                      <Typography  sx={{ fontWeight: 'regular',fontSize:15 }}> #{item.Channel.Name}</Typography> 
                      </Button>

                      <div style={{ float: "right", marginBottom: 10 }}>
                        <Button style={{ color: "black" }}>
                          <Link
                            to={"/EditPage/" + item.Id}
                            onClick={(e) => {
                              selectid(item.Id);
                            }}
                          >
                            <EditIcon style={{ color: "black" }} />
                          </Link>
                        </Button>
                        <Button onClick={handleClickOpen}>
                          <DeleteIcon style={{ color: "black" }} />
                        </Button>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>Confirmation for Delete</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {`To Delete the Stand up permanently Enter the name
                              of the standup ${item.Name} for confirmation.`}
                            </DialogContentText>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label={item.Name}
                              type="email"
                              onChange={deleteHandler}
                              fullWidth
                              variant="standard"
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleClose}
                              style={{ color: "black" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{ color: "black" }}
                              onClick={() => {
                                if (deleteText === item.Name.toLowerCase()) {
                                  axios
                                    .get(burl + `/delete/${item.Id}`)
                                    .then(() =>
                                      axios.get(baseurl).then((response) => {
                                        setData(response.data);
                                        console.log(response.data);
                                        setLoading(true);
                                      })
                                    );
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Button>
                          <Link
                            to={`/standup/${item.Id}/view`}
                            onClick={(e) => {
                              selectid(item.Id);
                            }}
                          >
                            <VisibilityIcon style={{ color: "black" }} />
                          </Link>
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ))
          ) : (
            <Card
              style={{
                minWidth: "50rem",
                height: "10rem",
                marginBottom: 2,
                marginTop: 5,
                backgroundColor:
                  colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              <CardContent>
                <Typography align="center" variant="h6">
                  No Data
                </Typography>
              </CardContent>
            </Card>
          )}
        </div>
      </ThemeProvider>
    );
  }
}
