import React from 'react';
import NewStandup from './UI/newStandup';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './UI/dashboard';
//import Upper from './UI/Display';
import './App.css';
import EditPage from './UI/editPage';
import View from './UI/view';
import Upper from './UI/view/Display';
import Login from './login';
function App() {


  return (

    <div className='bodyColor'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/newstandUp" element={<NewStandup />}></Route>
          <Route path="/EditPage/:id" element={<EditPage />}></Route>
          <Route path="/standup/:id/view" element={<Upper />}></Route>
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
/** <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/newstandUp" element={<NewStandup />}></Route>
          <Route path="/EditPage/:id" element={<EditPage />}></Route>
          <Route path="/standup/:id/view" element={<Upper />}></Route>
        </Routes>
      </BrowserRouter>*/