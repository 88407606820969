import React from "react";
import { Card, Typography } from "@mui/material";
import { Grid, Box } from "@mui/material";
//import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
const theme = createTheme();
theme.typography.body2 = {
  fontSize: "1.2rem",
};
export default function Questions(props) {
  const colors = [
    "#8B86E2",
    "#7ED6E9",
    "#7EECB5",
    "#ECBF3C",
    "#E0BBE4",
    " #957DAD",
    "#D291BC",
    "#88D5D5",
  ];
  const questions = props.sendQuestions;
 // console.log(questions);
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 2,
        marginTop: 1,
        border: "1px solid",
        borderColor: "black",
        fontWeight: "800",
        fontSize: "5rem",
        paddingBottom: 8,
        backgroundColor: "white",
      }}
    >
      <Typography variant="h6" style={{ fontWeight: 700 }}>
        Questions
      </Typography>
      {questions.map((question) => (
        <Grid container direction="row">
          <FiberManualRecordSharpIcon
            style={{
              fontSize: 10,
              paddingTop: "0.5rem",
              paddinRight: "0.4rem",
              color: colors[Math.floor(Math.random() * colors.length)],
            }}
          />
          <Typography
            variant="body2"
            style={{ fontStyle: "italic", fontSize: "1.2rem" }}
          >
            {question}
          </Typography>
        </Grid>
      ))}
    </Box>
  );
}
