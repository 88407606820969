import React, { useState } from "react";
/*import TimeSelector from "./components/TimeSelector";
import DaysOfTheWeek from "./components/DaysOfTheWeek";
import QuestionsStandup from "./components/questionStandup";
import BroadCastChannel from "./components/BroadCastChannel";
import ParticipantChannel from "./components/Participants";
import Header from "./components/Header";*/
import { ThemeProvider } from "@mui/material";
//import theme from "./components/themes";
import MiniDrawer from "../Drawer";
import useStore from "../../Store/store";
import { Button } from "@mui/material";
import { v4 as uuid } from 'uuid';
//import EndTimeSelector from "./components/EndTimeSelector";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import theme from '../ComponentsEditAndNewStandup/Components/theme';
import BroadCastChannel from "../ComponentsEditAndNewStandup/Components/BroadCastChannel";
import ParticipantChannel from "../ComponentsEditAndNewStandup/Components/Participants";
import Header from "../ComponentsEditAndNewStandup/Components/Header";
import TimeSelector from "../ComponentsEditAndNewStandup/Components/TimeSelector";
import DaysOfTheWeek from "../ComponentsEditAndNewStandup/Components/DaysOfTheWeek";
import QuestionsStandup from "../ComponentsEditAndNewStandup/Components/questionStandup";
export default function NewStandup() {
    let navigate = useNavigate();
    const goHome = () => {
        navigate("/dashboard");
      //  console.log("Naavigated");
    };
    const questions = [
        "How do you feel today?",
        "What did you do since yesterday?",
        "What you will do today?",
        "Anything Blocking your progress?",
    ];
    var timeArray = []
    var timeArray2 = []
    const [data, setData] =
        useState({
            Name: "New Standup",
            Days: [],
            Questions: questions,
            Channel: { Id: "", Name: "" },
            Users: [],
            Start: {
                Hour: 7,
                Minute: 0
            },
            End: {
                Hour: 7,
                Minute: 0
            }

        });

    function getDays(res) {
        setData((prevData) => ({ ...prevData, Days: res }));
    }
    function getQuestions(questions) {
        setData((prevData) => ({ ...prevData, Questions: questions }));
    }
    function getChannel(channel) {
        setData((prevData) => ({ ...prevData, Channel: channel }));
    }
    function getParticipants(people) {
        setData((prevData) => ({ ...prevData, Users: people }));
    }
    function getTime(time) {
        timeArray = time.split(":")
        setData((prevData) => ({ ...prevData, Start: { ...prevData.Start, Hour: parseInt(timeArray[0]) } }));
        setData((prevData) => ({ ...prevData, Start: { ...prevData.Start, Minute: parseInt(timeArray[1]) } }));
    }
    function getEndTime(time) {
        timeArray2 = time.split(":")
        setData((prevData) => ({ ...prevData, End: { ...prevData.End, Hour: parseInt(timeArray2[0]) } }));
        setData((prevData) => ({ ...prevData, End: { ...prevData.End, Minute: parseInt(timeArray2[1]) } }));
    }

    function getHeader(name) {
        setData((prevData) => ({ ...prevData, Name: name }))
    }
   // console.log(data);
    const burl = useStore((state) => state.burl);
    const baseurl = burl + "newstandup"
    return (
        <>
            <ThemeProvider theme={theme}>
                <MiniDrawer />
                <div style={{ float: "right" }}>
                    <Button variant="contained" onClick={() => {
                        axios.post(baseurl, data).then(
                            (response) => {
                                if (response.data.message === "Success") goHome();
                            }
                        )
                    }}>Create StandUp</Button>

                </div>
                <Header getData={getHeader} sendHeader={data.Name} />
                <h3 style={{ color: "grey", marginBottom: "0" }}>Start Time</h3>
                <TimeSelector getData={getTime} sendTime={data.Start} />
                <h3 style={{ color: "grey", marginBottom: "0" }}>End Time</h3>
                <TimeSelector getData={getEndTime} sendTime={data.End} />
                <DaysOfTheWeek getData={getDays} sendDays={data.Days} />
                <QuestionsStandup getData={getQuestions} sendQuestions={data.Questions} />
                <BroadCastChannel getData={getChannel} sendChannel={data.Channel} />
                <ParticipantChannel getData={getParticipants} sendParticipants={data.Users} />

            </ThemeProvider>
        </>


    )
}
/**    */